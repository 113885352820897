import React from "react"
import Layout from "../components/layout"
import sliderImage from "../images/o_nas/01.jpg"
import sliderImageMobile from "../images/m_02.jpg"
import sliderImageSmall from "../images/m_02b.jpg"
import { Link } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import ScrollBlock from "../components/scrollBlock"
import LinkBox from "../components/LinkBox";
import Banner from "../components/Banner";
import SubBanner from '../components/about/SubBanner'
import TimeLine from '../components/about/TimeLine'
import Areas from '../components/about/Areas'
import Steps from '../components/about/Steps'
import Boxes from '../components/about/Boxes'
import Fleet from '../components/about/Fleet'
import Why from '../components/about/Why'
import Partners from '../components/about/Partners'

const AboutUsPage = (props) => {
  const { t, i18n } = useTranslation();
  if (i18n.language !== "de") i18n.changeLanguage("de")

  return (
    <Layout props={props}>
      <div className="slider relative bg-black">
        <Banner
          sliderImage={sliderImage}
          sliderImageMobile={sliderImageMobile}
          sliderImageSmall={sliderImageSmall}
          title01={t("general.meet")}
          title02={t("general.us")}
          subtitle={t("about.title")}
          id="banner"
          link1={t("general.fleetHref")}
          link2={t("general.contactHref")}
        ></Banner>
        <ScrollBlock></ScrollBlock>
      </div>
      <SubBanner />
      <TimeLine />
      <Areas />
      <Steps />
      <Boxes />
      <Fleet />
      <Why />
      <Partners />
      <section>
        <div className="flex flex-wrap">
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/de/flotte/"
              cssClass="fleet"
              text={t("general.fleet")}
            ></LinkBox>
          </div>
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/de/kontakt/"
              cssClass="contact"
              text={t("general.contact")}
            ></LinkBox>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutUsPage;